import { getCode } from "../service/api.service";

export function getTimeParameters() {
  let prmstr = window.location.search.substring(1);
  if (prmstr != null && prmstr !== "") {
    const params = transformToAssocArray(prmstr);
    if (params["t"] !== undefined) {
      const values = params["t"].split(",");
      return values;
    }
    if (params["T"] !== undefined) {
      const values = params["T"].split(",");
      return values;
    }
  }
  return null;
}

function transformToAssocArray(prmstr: string) {
  let params: any = {};
  let prmarr = prmstr.split("&");
  for (let i = 0; i < prmarr.length; i++) {
    let tmparr = prmarr[i].split("=");
    params[tmparr[0]] = tmparr[1];
  }
  return params;
}

export function formatURLSnippet(startTime: number, endTime: number) {
  const code = getCode();
  // For testing
  const baseURL = window.location.origin;
  return `${baseURL}/${code}?t=${startTime},${endTime}`;
}

export function formatURLShare() {
  const code = getCode();
  // For testing
  const baseURL = window.location.origin;
  return `${baseURL}/${code}?t=0`;
}
