import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import { Details, getDetails } from "./service/api.service";
import MainPage from "./MainPage";
import LoadingScreen from "./LoadingScreen";

function App() {
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [details, setDetails] = useState<Details>();
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const info = await getDetails();
        console.log(info);
        setDetails(info);
        setTimeout(() => {
          setFetchingDetails(false);
        }, 1000);
      } catch (error) {
        setError("Vizzy not found, might be an invalid URL");
      }
    })();
  }, []);

  return (
    <>
      {!fetchingDetails ? (
        <MainPage details={details as Details} />
      ) : (
        <LoadingScreen error={error} />
      )}
    </>
  );
}

export default App;
