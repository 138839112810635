import React from "react";
import { Interactions } from "../service/api.service";

type ActionsRowProps = {
  handleModalOpen: () => void;
  interaction: Interactions;
  setModalType: (type: string) => void;
};

const ActionsRow = ({
  handleModalOpen,
  interaction,
  setModalType,
}: ActionsRowProps) => {
  function onActionClick(t: string) {
    handleModalOpen();
    setModalType(t);
  }

  return (
    <div className="flex justify-evenly my-2 align-center w-full">
      <button onClick={() => onActionClick("snippet")}>
        <img
          src={require("../assets/scissor.png")}
          className="h-7"
          alt={"Vizzy"}
        />
      </button>
      <button onClick={() => onActionClick("share")}>
        <img
          src={require("../assets/share.png")}
          className="h-7"
          alt={"Vizzy"}
        />
      </button>
      {interaction.info && (
        <button onClick={() => onActionClick("info")}>
          <img
            src={require("../assets/info.png")}
            className="h-7"
            alt={"Vizzy"}
          />
        </button>
      )}
      {interaction.web && (
        <button onClick={() => onActionClick("web")}>
          <img
            src={require("../assets/web.png")}
            className="h-7"
            alt={"Vizzy"}
          />
        </button>
      )}
      {interaction.social && (
        <button onClick={() => onActionClick("social")}>
          <img
            src={require("../assets/social.png")}
            className="h-7"
            alt={"Vizzy"}
          />
        </button>
      )}
    </div>
  );
};

export default ActionsRow;
