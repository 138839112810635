import React from "react";

type LoadingScreenProps = {
  error: string;
};

const LoadingScreen = ({ error }: LoadingScreenProps) => {
  return (
    <div className="h-screen bg-black flex flex-col items-center justify-center">
      <img
        src={require("./assets/logo.png")}
        alt="Vizzy"
        className="w-48 md:w-72 lg:w-96  "
      />
      <h1 className="text-white mt-4 opacity-20">{error}</h1>
    </div>
  );
};

export default LoadingScreen;
