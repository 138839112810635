/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import ReactSlider from "react-slider";
import { FaUndo, FaRedo } from "react-icons/fa";

import "./slider.css";
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";
import { Details } from "../service/api.service";
import { formatURLSnippet, getTimeParameters } from "../service/player.service";
import { toast } from "react-toastify";

type SliderProps = {
  details: Details;
};

const Slider = (props: SliderProps) => {
  const { details } = props;

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [initialStart, setInitialStart] = useState(0);
  const [origLength, setOrigLength] = useState(0);
  const [beginning, setBeginning] = useState(0);

  const animationRef = useRef<any>(null);
  const audioPlayer = useRef<any>(null);
  const progressBar = useRef<any>();
  let progressTexts: any[] = [];
  function onloadedmetadata() {
    const startEnd = getTimeParameters();
    let min = 0;
    let max = 0;
    if (startEnd && startEnd.length > 0) {
      if (startEnd[0]) {
        min = +startEnd[0];
      }
      if (startEnd[1]) {
        max = +startEnd[1];
      }
    }
    const seconds = Math.floor(audioPlayer.current.duration);
    const maximum = max === 0 ? seconds : max;
    setOrigLength(maximum);
    setDuration(maximum);
    setBeginning(min);
    setInitialStart(min);
    audioPlayer.current.currentTime = min;
    progressBar.current.value = min;
    progressBar.current.min = min;
    progressBar.current.max = maximum;
  }

  const changePlayerCurrentTime = () => {
    setCurrentTime(progressBar.current.value);
  };

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  function adjustRange(values: number[]) {
    audioPlayer.current.currentTime = values[0];
    progressBar.current.value = values[0];

    setBeginning(values[0]);
    setCurrentTime(values[0]);
    setDuration(values[1]);
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  function backTen() {
    progressBar.current.value = Number(progressBar.current.value - 10);
    changeRange();
  }

  function forwardTen() {
    progressBar.current.value = (
      Number(progressBar.current.value) + 10
    ).toString();
    changeRange();
  }

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  useEffect(() => {
    if (+currentTime >= +duration) {
      audioPlayer.current.pause();
      progressBar.current.value = beginning;
      setIsPlaying(false);
      changeRange();
    }
  }, [currentTime]);

  useEffect(() => {
    const pStart = progressTexts[0].getBoundingClientRect();
    const pEnd = progressTexts[1].getBoundingClientRect();
    if (duration > 0) {
      if (pEnd.left - pStart.left < 50) {
        progressTexts[1].style.position = "absolute";
        progressTexts[1].style.top = "-60px";
      } else {
        progressTexts[1].style.position = "absolute";
        progressTexts[1].style.top = "0px";
      }
    }
  }, [duration, currentTime]);

  function copyToClipBoard() {
    navigator.clipboard.writeText(formatURLSnippet(beginning, duration));
    toast.info("Copied to clipboard");
  }

  return (
    <>
      <audio
        onLoadedMetadata={onloadedmetadata}
        ref={audioPlayer}
        src={details.vizzy.episode.audio_url}
        preload="metadata"
      ></audio>
      <input
        ref={progressBar}
        className="progress-bar"
        type="range"
        onChange={changeRange}
      ></input>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        min={+initialStart}
        max={+origLength}
        value={[+beginning, +duration]}
        ariaLabel={["Lower thumb", "Upper thumb"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => {
          return (
            <div {...props}>
              <h6
                ref={(ref) => (progressTexts[state.index] = ref)}
                className={`mt-10 text-white ml-[-12px] text-xs`}
              >
                {calculateTime(state.valueNow)}
              </h6>
            </div>
          );
        }}
        onAfterChange={(values) => adjustRange(values)}
        pearling
        minDistance={10}
      />
      <img
        src={require("../assets/soundwave.png")}
        alt="Snippet sound wave"
        className="w-[250px] h-[40px] mt-3"
      />
      <div className="w-36 h-12 bg-[#620405] mt-12 flex justify-center items-stretch content-around rounded-md">
        <button onClick={backTen}>
          <FaUndo className="text-white text-xl md:text-1xl" />
        </button>
        <button onClick={togglePlayPause} className="mx-3">
          {!isPlaying ? (
            <AiFillPlayCircle className="text-white text-2xl md:text-3xl" />
          ) : (
            <AiFillPauseCircle className="text-white text-2xl md:text-3xl" />
          )}
        </button>
        <button onClick={forwardTen}>
          <FaRedo className="text-white text-xl md:text-1xl" />
        </button>
      </div>
      <button
        onClick={copyToClipBoard}
        className="mt-8 bg-white border border-red-600 rounded-md"
      >
        <p className="font-bold text-center text-xs px-3 py-1">Share Vizzy</p>
      </button>
    </>
  );
};

export default Slider;
