import React, { useRef, useState, useEffect } from "react";
import { FaUndo, FaRedo } from "react-icons/fa";
import {
  AiFillPlayCircle,
  AiFillPauseCircle,
  AiOutlineConsoleSql,
} from "react-icons/ai";

import styles from "../player.module.css";
import { Details } from "../service/api.service";
import { getTimeParameters } from "../service/player.service";

type PlayerProps = {
  details: Details;
  currentTime: number;
  setCurrentTime: (n: number) => void;
};

const Player = (Props: PlayerProps) => {
  const { details, currentTime, setCurrentTime } = Props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const audioPlayer = useRef<any>();
  const progressBar = useRef<any>();
  const animationRef = useRef<any>();

  function onloadedmetadata() {
    const seconds = Math.floor(audioPlayer.current.duration);
    const maximum = end === 0 || !end ? seconds : end;
    setDuration(maximum);
    progressBar.current.min = start;
    progressBar.current.value = start;
    progressBar.current.max = maximum;
    changeRange();
  }

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = Number(progressBar.current.value);
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${((progressBar.current.value - start) / (duration - start)) * 100}%`
    );
    setCurrentTime(progressBar.current.value);
  };

  const backTen = () => {
    progressBar.current.value = Number(progressBar.current.value - 10);
    changeRange();
  };

  const forwardTen = () => {
    // Converting to a string fixes skipping to the end issue
    progressBar.current.value = (
      Number(progressBar.current.value) + 10
    ).toString();
    changeRange();
  };

  useEffect(() => {
    const startEnd = getTimeParameters();
    if (startEnd && startEnd.length > 0) {
      setStart(startEnd[0]);
      setEnd(startEnd[1]);
    }
  }, []);

  useEffect(() => {
    if (+currentTime >= +duration) {
      audioPlayer.current.pause();
      progressBar.current.value = start;
      setIsPlaying(false);
      changeRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  return (
    <div className="flex flex-col p-5 bg-[#620405] w-64 md:w-96 h-40 mb-12 rounded-md">
      <audio
        onLoadedMetadata={onloadedmetadata}
        ref={audioPlayer}
        src={details?.vizzy?.episode?.audio_url ?? ""}
        preload="metadata"
      ></audio>
      <input
        ref={progressBar}
        className={styles.progressBar}
        type="range"
        onChange={changeRange}
      ></input>
      <div className="flex-1 flex flex-row justify-between -mt-4">
        <h3 className="text-white text-base">{calculateTime(currentTime)}</h3>
        <h3 className="text-white text-base">
          {duration && !isNaN(duration) && calculateTime(duration)}
        </h3>
      </div>
      <div className="flex-1 flex flex-row justify-evenly items-center -mt-4">
        <button onClick={backTen}>
          <FaUndo className="text-white text-xl md:text-3xl" />
        </button>
        <button onClick={togglePlayPause}>
          {!isPlaying ? (
            <AiFillPlayCircle className="text-white text-3xl md:text-6xl" />
          ) : (
            <AiFillPauseCircle className="text-white text-3xl md:text-6xl" />
          )}
        </button>
        <button onClick={forwardTen}>
          <FaRedo className="text-white text-xl md:text-3xl" />
        </button>
      </div>
    </div>
  );
};

export default Player;
