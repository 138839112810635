import React, { useEffect, useState } from "react";
import {
  FaEnvelopeOpen,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import Card from "./components/Card";
import CustomModal from "./components/CustomModal";
import Player from "./components/Player";
import { Cards, CurrentCard, Details } from "./service/api.service";
import { Actions } from "./types";

type MainPageProps = {
  details: Details;
};

const MainPage = (Props: MainPageProps) => {
  const { details } = Props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [currentCard, setCurrentCard] = useState<Cards>();
  const [currentTime, setCurrentTime] = useState(0);
  const [currentPlayingCardIndex, setCurrentPlayingCardIndex] = useState(0);

  function handleModalOpen() {
    setIsOpen(true);
  }

  function hideModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    let defaultIfNoVizzy = {
      card_id: 0,
      start: 0,
      end: 0,
      image: "",
      index: 0,
      interactions: {
        info: false,
        product: false,
        social: false,
        web: false,
      },
    };
    if (details) {
      if (!details.vizzy.cards)
        return setCurrentCard({
          ...defaultIfNoVizzy,
          image: details.vizzy.episode.artwork,
        } as any);
      if (!details.vizzy.cards.length)
        return setCurrentCard({
          ...defaultIfNoVizzy,
          image: details.vizzy.episode.artwork,
        } as any);
      setCurrentCard(details.vizzy.cards[0]);
    }
  }, [details]);

  function getIndex(currentTime: number) {
    let cards = details.vizzy.cards;
    let i;
    for (i = 0; i < cards.length && currentTime >= cards[i].start; i++) {}
    return i - 1;
  }

  useEffect(() => {
    if (details.vizzy.cards && currentCard) {
      const indx = getIndex(currentTime);
      setCurrentPlayingCardIndex(indx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  useEffect(() => {
    let cards = details.vizzy.cards;
    if (cards) {
      setCurrentCard(cards[currentPlayingCardIndex]);
    }
  }, [currentPlayingCardIndex, details.vizzy.cards]);

  return (
    <>
      <CustomModal
        currentCard={currentCard as CurrentCard}
        closeModal={hideModal}
        isOpen={modalIsOpen}
        type={modalType as Actions}
        details={details as Details}
      />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-col bg-black items-center">
        <img
          src={require("./assets/logo.png")}
          className="h-32 md:h-36 object-cover py-6"
          alt="vizzy logo"
        />
        <h1 className="text-white text-sm text-center w-64 md:w-96 text-md my-6 px-2">
          {details.type !== "episode" ? `A` : "An"} {details.type} has been
          shared with you from the vizzy podcast app
        </h1>

        {details.type === "podcast" ? (
          <img
            src={details.og_image}
            alt={details.vizzy.podcast.title}
            className="w-64 md:w-96 object-cover"
          />
        ) : (
          <Card
            setModalType={setModalType}
            currentCard={currentCard as Cards}
            handleModalOpen={handleModalOpen}
          />
        )}

        <h3 className="text-white w-64 md:w-96 mt-8 text-center">
          {details.vizzy.episode?.title ?? details.vizzy.podcast.title}
        </h3>
        <h3 className="text-gray-500 w-64 md:w-96 text-sm my-5 text-center">
          {details.type === "podcast"
            ? details.vizzy.podcast.author
            : details.vizzy.podcast.title}
        </h3>

        {details.type === "podcast" ? (
          <p className="text-white w-64 md:w-96 text-center text-sm my-8 max-w-lg px-4">
            {details.vizzy.podcast.description}
          </p>
        ) : (
          <Player
            details={details as Details}
            currentTime={currentTime}
            setCurrentTime={(s) => setCurrentTime(s)}
          />
        )}
      </div>
      <div className="flex flex-col items-center py-16">
        <h1 className="text-lg font-bold text-center">
          Improve your podcast listening experience
        </h1>
        <div className="flex flex-row flex-wrap items-center justify-center">
          <div className="w-44 my-2 mx-2">
            <a href="https://apps.apple.com/au/app/vizzy/id1600632894">
              <img
                src={require("./assets/app_store_button.png")}
                alt="Download on the app store"
              />
            </a>
          </div>
          <div className="w-44 my-2 mx-2 ">
            <a href="https://apps.apple.com/au/app/vizzy/id1600632894">
              <img
                src={require("./assets/google_play_button.png")}
                alt="Download on the app store"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-row flex-wrap justify-center bg-[#2a2e32] py-10">
        <div className="w-72 px-4 py-4">
          <h3 className="text-sm text-white font-medium border-[#ffffff20]  border-b">
            About Vizzy
          </h3>
          <p className="text-xs mt-4 text-white">
            Vizzy brings your favourite podcast moments to life. Interact with
            the best parts of your shows in a totally new and immersive way.
          </p>
        </div>
        <div className="w-72 px-4 py-4">
          <h3 className="text-sm text-white font-medium border-[#ffffff20] border-b">
            Contact Info
          </h3>
          <a target="__blank" href="mailto:hello@vizzy.com.au">
            <span className="inline-flex items-center mt-4">
              <FaEnvelopeOpen className="fa fa-envelope-open-o text-white text-xs" />
              <p className="text-xs text-white ml-2">hello@vizzy.com.au</p>
            </span>
          </a>
        </div>
        <div className="w-72 px-4 py-4">
          <h3 className="text-sm text-white font-medium border-[#ffffff20] border-b">
            Socials
          </h3>
          <span className="inline-flex items-center mt-4">
            <a href="https://www.facebook.com/vizzy.podcasting">
              <FaFacebook className="fa fa-envelope-open-o text-white text-md" />
            </a>
            <a href="https://www.instagram.com/vizzy.fm">
              <FaInstagram className="fa fa-envelope-open-o text-white text-md mx-5" />
            </a>
            <a href="https://www.linkedin.com/company/vizzy-fm/">
              <FaLinkedin className="fa fa-envelope-open-o text-white text-md" />
            </a>
          </span>
        </div>
      </div>
      <div className="flex flex-row flex-wrap justify-center bg-black py-10">
        <h2 className="text-white text-sm text-center max-w-[90%]">
          Sopod Ptd Ltd © 2022 -{" "}
          <a href="https://vizzy.fm/terms-of-use/"> Terms of Services </a> -
          <a href="https://vizzy.fm/privacy-policy/">Privacy Policy</a>
        </h2>
      </div>
    </>
  );
};

export default MainPage;
