const IS_LIVE = false;

const BASE_URL = IS_LIVE
  ? "https://share.vizzy.fm/api/"
  : "https://staging.share.vizzy.fm/api/";

type Info = {
  data: {
    image: string;
    title: string;
    content: string;
  };
  icon: string;
};

type Links = {
  type: string;
  icon: string;
  url: string;
};

type Social = {
  data: [
    {
      title: string;
      links: Links[];
    }
  ];
  icon: string;
};

type Web = {
  data: [
    {
      title: string;
      content: string;
      links: Links[];
      image: string;
    }
  ];
  icon: string;
};

export type Interactions = {
  info: Info;
  social: Social;
  web: Web;
  product: boolean;
};

// If it's the whole
export type Cards = {
  card_id: number;
  start: number;
  end: number;
  image: string;
  interactions: Interactions;
};

export type CurrentCard = Cards & {
  index: number;
};

type Episode = {
  artwork: string;
  audio_url: string;
  description: string;
  duration: string;
  ep_num: string;
  guid: string;
  has_vizzy: boolean;
  pub_date: string;
  title: string;
};

type Podcast = {
  artwork: string;
  author: string;
  categories: string[];
  description: string;
  episodes_count: number;
  feed_url: string;
  language: string;
  title: string;
};

type Details = {
  end: any;
  og_image: string;
  start: null;
  type: string;
  vizzy: {
    cards: Cards[];
    episode: Episode;
    podcast: Podcast;
    id: string;
    image: string;
  };
};

export function getCode() {
  const rawCode = window.location.pathname;
  return rawCode.replace(/\//g, "");
}

async function getDetails() {
  try {
    const code = getCode();
    const netRequest = await fetch(BASE_URL + code);
    const data = await netRequest.json();
    return data as Details;
  } catch (error) {
    throw error;
  }
}

export { getDetails, Details };
