import React from "react";
import Modal from "react-modal";
import {
  AiFillCloseCircle,
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { toast } from "react-toastify";

import styles from "../modal.module.css";
import Slider from "./Slider";
import { Actions } from "../types";
import { CurrentCard, Details } from "../service/api.service";
import { formatURLShare } from "../service/player.service";

type CustomModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  type: Actions;
  details: Details;
  currentCard: CurrentCard;
};

type ModalContentProps = {
  type: Actions;
  details: Details;
  currentCard: CurrentCard;
};

const ModalContent = ({ type, details, currentCard }: ModalContentProps) => {
  function copyToClipBoard() {
    navigator.clipboard.writeText(formatURLShare());
    toast.info("Copied to clipboard");
  }

  function openWebsite(link: string) {
    window.open(link);
  }
  let view: JSX.Element = <></>;
  switch (type) {
    case "snippet":
      view = (
        <>
          <h1 className="text-white py-7 text-center">
            Select snippet using the white boundary lines. Share the audio &
            screen direct to social media, friends.{" "}
          </h1>
          <Slider details={details} />
        </>
      );
      break;
    case "share":
      view = (
        <>
          <p className="text-gray-50 text-sm text-center py-8">
            Share this section of {details.vizzy.episode.title} with your
            friends directly or via social media
          </p>
          <img src={details.vizzy.episode.artwork} alt="" className="w-56" />
          <button
            onClick={copyToClipBoard}
            className="mt-8 bg-white border border-red-600 rounded-md"
          >
            <p className="font-bold text-center text-xs px-3 py-1">
              Share Vizzy
            </p>
          </button>
        </>
      );
      break;
    case "info":
      view = (
        <>
          <h1 className="text-white py-7 text-center">
            {currentCard.interactions.info.data.title}
          </h1>
          <img src={currentCard.interactions.info.data.image} alt="" />
          <p className="text-white py-7 text-center">
            {currentCard.interactions.info.data.content}
          </p>
        </>
      );

      break;
    case "web":
      view = (
        <>
          <p className="text-white py-7 text-center">
            {currentCard.interactions.web.data[0].content}
          </p>
          <img src={currentCard.interactions.web.data[0].image} alt="" />
          <button
            onClick={() =>
              openWebsite(currentCard.interactions.web.data[0].links[0].url)
            }
            className="mt-8 bg-white border border-red-600 rounded-md"
          >
            <p className="font-bold text-center text-xs px-3 py-1">
              Open website
            </p>
          </button>
        </>
      );
      break;
    case "social":
      view = (
        <>
          <h1 className="text-white py-7 text-center">
            {currentCard.interactions.social.data[0].title}
          </h1>
          <div className="mt-12 flex flex-row">
            {currentCard.interactions.social.data[0].links.map((s) => {
              if (s.type === "twitter") {
                return (
                  <a href={s.url}>
                    <AiFillTwitterCircle className="text-white text-4xl mx-6" />
                  </a>
                );
              } else if (s.type === "facebook") {
                return (
                  <a href={s.url}>
                    <AiFillFacebook className="text-white text-4xl mx-6" />
                  </a>
                );
              } else {
                return (
                  <a href={s.url}>
                    <AiFillInstagram className="text-white text-4xl mx-6" />
                  </a>
                );
              }
            })}
          </div>
        </>
      );
  }
  return view;
};

const CustomModal = (props: CustomModalProps) => {
  const { isOpen, closeModal, type, details, currentCard } = props;
  function getTitle(type: string) {
    switch (type) {
      case "snippet":
        return "Snippet";
      case "share":
        return "Share";
      case "social":
        return "Social Media";
      case "info":
        return currentCard.interactions.info.data.title;
      case "website":
        return currentCard.interactions.web.data[0].title;
      default:
        return "";
    }
  }

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={isOpen}
      ariaHideApp={false}
      className={styles.modal}
      style={{
        overlay: {
          backgroundColor: "rgba(158, 9, 7, 0.25)",
        },
      }}
      contentLabel="Example Modal"
    >
      <div className="flex flex-col w-full h-full items-center">
        <h1 className="text-white text-3xl">{getTitle(type)}</h1>
        <ModalContent currentCard={currentCard} details={details} type={type} />
        <button className="mt-auto" onClick={closeModal}>
          <AiFillCloseCircle className="text-white text-2xl my-7" />
        </button>
      </div>
    </Modal>
  );
};

export default CustomModal;
