import React, { useState } from "react";

import actions from "../actions";
import { Cards } from "../service/api.service";
import ActionsRow from "./ActionsRow";

type CardProps = {
  handleModalOpen: () => void;
  currentCard: Cards;
  setModalType: (s: string) => void;
};

const Card = (Props: CardProps) => {
  const { handleModalOpen, currentCard, setModalType } = Props;
  return (
    <div className="px-2 py-2 flex flex-col items-center border-solid border-white border rounded-md">
      {currentCard && (
        <ActionsRow
          interaction={currentCard.interactions}
          handleModalOpen={handleModalOpen}
          setModalType={setModalType}
        />
      )}
      {currentCard && (
        <div className="flex flex-1 flex-col justify-center">
          <img
            src={currentCard.image}
            alt={"Vizzy"}
            className="mt-2 w-64 md:w-96  h-96 md:h-128 object-cover"
          />
        </div>
      )}
    </div>
  );
};

export default Card;
